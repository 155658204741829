import { useContext } from 'react';
import { Anchor, Avatar, Box, Grid, Image, Text, Tip, ThemeContext } from 'grommet';
import { Action, CircleQuestion, Key, Contact, Link as LinkIcon, SettingsOption } from 'grommet-icons';
import { dispatch } from 'use-bus';
import { navigate, Link } from '@reach/router';

import config, { hostUrl } from '../config';
import { useSessionStore, useToken } from '../hooks';

const AccountPanel = () => {
  const session = useSessionStore((state) => state.session);
  const token = useToken();
  const isDarkMode = useContext(ThemeContext).dark;

  if (!session?.authenticated) return null;

  let logoutUrl = `${session.services.APIHost}/account/logout?returnUrl=${encodeURIComponent(window.location.href)}`;

  if (config.useTokenAuth && token?.access_token) {
    logoutUrl += `&revoke_token=${token?.access_token}`;
  }

  const onLogOut = () => {
    if (config.useTokenAuth && token?.access_token) {
      dispatch({ type: 'pkce.clear', logoutUrl });
      return;
    }

    navigate(logoutUrl);
  };

  return (
    <Box align="stretch" elevation="xlarge" direction="column" alignSelf="start" justify="start" background="background-front" width="375px">
      <Box pad={{ horizontal: 'medium', vertical: 'small' }}>
        <Box align="center" justify="between" direction="row" fill="horizontal" gap="medium">
          <Box direction="row" align="center">
            <Text wordBreak="break-word" style={{ hyphens: 'auto', wordWrap: 'break-word' }}>
              {session.companyName}
              {session.isOIDC && (
                <Tip
                  content={
                    <Box pad="xsmall">
                      <Text size="small">Your account is managed by {session.oidcProvider}</Text>
                    </Box>
                  }
                >
                  <Text>
                    &nbsp;<LinkIcon size="small" style={{ marginLeft: '10px' }}></LinkIcon>
                  </Text>
                </Tip>
              )}
            </Text>
          </Box>
          <Box flex={{ shrink: 0 }}>
            <Anchor href={logoutUrl} onClick={onLogOut}>
              Log out
            </Anchor>
          </Box>
        </Box>
        <Box align="center" justify="start" pad={{ top: 'medium' }} direction="row" fill="horizontal">
          <Box align="center" justify="center" flex={{ shrink: 0 }}>
            <Avatar align="center" flex={false} justify="center" overflow="hidden" round="full" src={session.avatarUrl} size="large" />
          </Box>
          {session.DisplayName ? (
            <Box align="start" justify="center" pad={{ left: 'small' }}>
              <Text size="medium" weight="bold">
                {session.DisplayName}
              </Text>
              <Text size="small" color="text-weak" truncate>
                {session.Email}
              </Text>
            </Box>
          ) : (
            <Box align="start" justify="center" pad={{ left: 'small' }}>
              <Text size="medium" weight="bold" truncate>
                {session.Email}
              </Text>
            </Box>
          )}
        </Box>
        <Box align="start" justify="center" fill margin={{ vertical: 'small' }} pad={{ top: 'small' }} gap="small">
          <Anchor as={Link} to="/app/assistant/myconnectors">
            <Box align="center" justify="start" direction="row" gap="small">
              <Action />
              <Text>Linked accounts</Text>
            </Box>
          </Anchor>
          {!session.isOIDC && (
            <Anchor as={Link} to="/app/assistant/changepassword">
              <Box align="center" justify="start" direction="row" gap="small">
                <Key />
                <Text>Change password</Text>
              </Box>
            </Anchor>
          )}
          {session.Roles.includes('Administrator') && (
            <Anchor href={`${hostUrl}/admin/getstarted`} target="_blank">
              <Box align="center" justify="start" direction="row" gap="small">
                <SettingsOption />
                <Text>Administration</Text>
              </Box>
            </Anchor>
          )}
          <Anchor href="https://www.adenin.com/feedback/" target="_blank">
            <Box align="center" justify="start" direction="row" gap="small">
              <Contact />
              <Text>Send feedback</Text>
            </Box>
          </Anchor>
          <Anchor href="https://www.adenin.com/support/" target="_blank">
            <Box align="center" justify="start" direction="row" gap="small">
              <CircleQuestion />
              <Text>Help</Text>
            </Box>
          </Anchor>
        </Box>
      </Box>
      <Box align="start" fill="horizontal" margin={{ top: 'small' }} pad={{ horizontal: 'medium', vertical: 'small' }} background="background-contrast">
        <Text size="xsmall" weight="bold">
          Add your Assistant to a Channel:
        </Text>
        <Grid fill="horizontal" gap="small" columns={['calc(33% - 12px)', 'calc(33% - 12px)', 'calc(33% - 12px)']} margin={{ top: 'small' }}>
          {session.channels.map((channel) => {
            return (
              <Anchor
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', textAlign: 'center' }}
                href={channel.link}
                key={channel.link}
                target="_blank"
              >
                <Box
                  align="center"
                  justify="center"
                  background={{ color: 'white' }}
                  round="small"
                  elevation="xsmall"
                  width="xxsmall"
                  height="xxsmall"
                  pad="xsmall"
                  border={{ color: 'active', size: 'xsmall' }}
                >
                  <Image src={channel.icon} fit="contain" />
                </Box>
                <Text size="xsmall" margin={{ top: 'xsmall' }}>
                  {channel.title}
                </Text>
              </Anchor>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default AccountPanel;
