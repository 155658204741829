import { Box, Button, Text } from 'grommet';
import { FormClose } from 'grommet-icons';
import { Suspense, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { dispatch } from 'use-bus';

import { sidebarCatalog } from '../../sidebars/catalog';
import { isMobilePlatform } from '../../utils';
import PlaceholderLoading from '../placeholder-loading';

const ApiErrorCard = lazy(() => import('../api-error-card/api-error-card'));

const AppSidebar = (props) => {
  function toggleSidebar() {
    dispatch({ type: 'sidebar.toggle', open: false }); // close sidebar
  }

  let SidebarContent = null;

  // check if custom react component should be used to render specific card Id
  const sidebar = sidebarCatalog[props.title];
  if (sidebar) {
    //console.log('render react card ' + metadata?.Title);
    SidebarContent = sidebar;
  } else {
    SidebarContent = sidebarCatalog['empty'];
  }

  // scroll page container to top
  // *todo* anylze why scroll position is restored
  const container = document.getElementById('pageContainer');
  if (container) container.scrollTo(0, 0);

  const innerSidebar = (
    <Suspense fallback={<PlaceholderLoading shape="rect" width={160} height={24} />}>
      <ErrorBoundary FallbackComponent={ApiErrorCard}>
        <SidebarContent {...props} />
      </ErrorBoundary>
    </Suspense>
  );

  return (
    <Box
      flex
      width="medium"
      fill="horizontal"
      background="background"
      pad={props.wipeDefault ? '' : 'small'}
      elevation="large"
      style={{ zIndex: '1000', minWidth: '384px', maxWidth: isMobilePlatform ? '100%' : '384px' }}
    >
      {props.wipeDefault ? (
        innerSidebar
      ) : (
        <>
          <Box direction="row" align="center" as="header" justify="between">
            <Text size="xlarge">{props.title}</Text>
            <Button icon={<FormClose />} onClick={() => toggleSidebar()} />
          </Box>
          <Box flex overflow="auto" pad="xsmall">
            {innerSidebar}
          </Box>
          {props.title !== 'Hello' && props.title !== 'Assistant' && (
            <Box as="footer" border={{ side: 'top' }} pad="small" justify="end" direction="row" align="center">
              <Button primary label="Save" />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default AppSidebar;
