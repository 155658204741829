import { Avatar, Box, Button, DropButton, Header, Image, Text, ThemeContext } from 'grommet';
import { AddCircle, Notification, SettingsOption, StatusGood } from 'grommet-icons';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useBus, { dispatch } from 'use-bus';

import { Link, navigate } from '@reach/router';

import config from '../../config';
import { usePlatformQueries, usePlatformQuery, useSessionStore } from '../../hooks';
import AccountPanel from '../account-panel';
import helix from './assistant_helix_icon.svg';

const Logo = styled.img`
  max-width: 200px;
  max-height: 48px;
  object-fit: cover;
`;

const NotificationBadge = ({ notificationCards, toggleSidebar }) => {
  notificationCards = notificationCards.filter((item) => item.UserActions.subscribed);

  const results = usePlatformQueries(notificationCards && !notificationCards.ErrorCode ? notificationCards.map((item) => `/gateway/card/${item.Id}`) : []);

  const actionable = results?.filter((result) => result.isFetched && result.data.Data.actionable);
  const count = actionable?.filter((result, index) => result.data.Data._hash !== notificationCards[index].ReadVersion).length;

  const [lastHash, setLastHash] = useState(null);
  const [showBadge, setShowBadge] = useState(true);

  useEffect(() => {
    if (!actionable.length || !hash) {
      return;
    }

    const hash = actionable.map((result) => result.data.Data._hash).reduce((prev, next) => prev + next);

    if (lastHash != hash) {
      setShowBadge(true);
      setLastHash(hash);
      return;
    }

    setShowBadge(false);
    setLastHash(hash);
  }, [actionable, lastHash, results]);

  const onClick = () => {
    toggleSidebar('Notifications', true);
    setShowBadge(false);
  };

  return (
    <Button
      icon={<Notification />}
      badge={
        count && showBadge
          ? {
              value: count,
              max: 99,
            }
          : null
      }
      onClick={onClick}
      margin={{ right: 'small' }}
    />
  );
};

const AppHeader = () => {
  console.log(`AppHeader rendering`);

  const { data } = usePlatformQuery(`/userworkplace/usercards`);
  const session = useSessionStore((state) => state.session);
  const isDarkMode = useContext(ThemeContext).dark;

  const [accountOpen, setAccountOpen] = useState(false);

  useBus('overlays.close', () => setAccountOpen(false));

  if (!session?.authenticated) return null;

  function toggleSidebar(title, wipeDefault = false) {
    dispatch({ type: 'sidebar.toggle', title, wipeDefault });
  }

  return (
    <Header background="background" align="center" direction="row" flex={false} justify="between" gap="medium" fill="horizontal" pad="small" position="sticky">
      <Box align="center" justify="center" direction="row" gap="small">
        <Box align="center" justify="center" direction="column" gap="none" wrap={false} responsive={false}>
          <Link to="/app/assistant/board">
            <Logo src={session.logoUrl} />
          </Link>
        </Box>
        {session.demoMode && (
          <Box direction="row" gap="xsmall" align="center" justify="center" fill="vertical" className="demo-indicator">
            <StatusGood color="status-ok" />
            <Text size="small" margin={{ left: 'xsmall' }}>
              Demo mode is active
            </Text>
            <Button
              icon={<SettingsOption size="18px" />}
              href={`${config.hostUrl}/app/demomode`}
              target="_blank"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            />
          </Box>
        )}
      </Box>
      <Box align="center" justify="center" direction="row">
        {data && <NotificationBadge notificationCards={data.Data} toggleSidebar={toggleSidebar} />}
        <Button
          icon={<Image src={helix} style={isDarkMode ? { filter: 'invert(1) brightness(2)', verticalAlign: 'middle' } : { verticalAlign: 'middle' }} />}
          onClick={() => toggleSidebar('Assistant', true)}
          className="chat-btn"
          margin={{ right: 'small' }}
          style={{ paddingBottom: '10px' }}
        />
        <Button icon={<AddCircle />} onClick={() => navigate('/app/assistant/mycards')} className="add-remove-btn" margin={{ right: 'small' }} />
        <DropButton
          dropAlign={{ top: 'bottom', right: 'right' }}
          open={accountOpen}
          onOpen={() => setAccountOpen(true)}
          onClose={() => setAccountOpen(false)}
          dropContent={<AccountPanel></AccountPanel>}
        >
          <Avatar align="center" flex={false} src={session.avatarUrl} justify="center" overflow="hidden" round="full" size="medium" />
        </DropButton>
      </Box>
    </Header>
  );
};

export default AppHeader;
